import React, { useState } from 'react'
import './Testimonials.css'
import {testimonailsData, testimonialsData} from '../../src/data/testimonialsData'
import leftArrow  from '../../src/assets/leftArrow.png'
import  rightArrow from '../../src/assets/rightArrow.png'
import {motion} from 'framer-motion'

const Testimonials = () => {
    const transition ={type: 'spring' , duration :3}
    const [selected , setselected] = useState(0);
    const tlength = testimonialsData.length;
  return (
<div className="Testimonials">
    <div className="left-t">
        <span>Testimonials</span>
        <span className="stroke-text">What they </span>
        <span>say about us</span>
        <motion.div
        key={selected}
        initial={{opacity:0 , x:-100}}
        animate={{opacity:1 ,x:0}}
        exit={{opacity:0 , x:100}}
        transition={transition}

        >
            {testimonialsData[selected].review}
        </motion.div>
        <span>
            <span style={{color:'var(--orange)'}}>
                {testimonialsData[selected].name}
            </span>{" "}
            - {testimonialsData[selected].status}
        </span>
    </div>
    <div className="right-t">
        <motion.div
        initial={{opacity :0 ,x: -100}}
        transition={{...transition , duration:2}}
        whileInView={{opacity:1 , x:0}}

        ></motion.div>
        <motion.div
         initial={{opacity :0 ,x: 100}}
         transition={{...transition , duration:2}}
         whileInView={{opacity:1 , x:0}}
        

        ></motion.div>
        < motion.img
        key={selected}
        initial={{opacity:0 , x:100}}
        animate={{opacity:1 ,x:0}}
        exit={{opacity:0 , x:-100}}
         src={testimonialsData[selected].image} alt="" />
        <div className="arrows">
            <img
            onClick={()=>{
                selected===0?setselected(tlength -1):
                selected((prev)=>prev -1)
            }
            }
            src={leftArrow} alt="" />
            <img 
            onClick={()=>{
                selected===tlength - 1
            ? setselected(0)
            :setselected((prev)=>prev+1)
                    }}
            src={rightArrow} alt="" />
        </div>
    </div>
</div> 
 )
}

export default Testimonials