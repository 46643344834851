import './App.css';
import Plans from './Plans/Plans';
import Reasons from './Reasons/Reasons';
import Testimonials from './Testimonials/Testimonials';
import Hero from "./components/Hero/hero"
import Programs from './components/Programs/Programs';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div className="App">

      <Hero/> 
      <Programs/>
      <Reasons/>
      <Plans/>
      <Testimonials/>
      <Join/>
      <Footer/>
   </div>
  );
}

export default App;
